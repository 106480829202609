@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?4m74x8');
  src:  url('assets/fonts/icomoon.eot?4m74x8#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?4m74x8') format('truetype'),
    url('assets/fonts/icomoon.woff?4m74x8') format('woff'),
    url('assets/fonts/icomoon.svg?4m74x8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-circle:before {
  content: "\e900";
}
.icon-bag:before {
  content: "\e901";
}
.icon-bank:before {
  content: "\e91b";
}
.icon-call:before {
  content: "\e91c";
}
.icon-flower .path1:before {
  content: "\e90f";
  color: rgb(255, 255, 255);
}
.icon-flower .path2:before {
  content: "\e910";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path3:before {
  content: "\e911";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path4:before {
  content: "\e912";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path5:before {
  content: "\e913";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path6:before {
  content: "\e914";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path7:before {
  content: "\e915";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path8:before {
  content: "\e916";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-flower .path9:before {
  content: "\e917";
  margin-left: -0.630859375em;
  color: rgb(0, 0, 0);
}
.icon-gift:before {
  content: "\e918";
}
.icon-grape:before {
  content: "\e919";
}
.icon-group:before {
  content: "\e902";
}
.icon-gym:before {
  content: "\e91a";
}
.icon-help-2:before {
  content: "\e903";
}
.icon-help:before {
  content: "\e904";
}
.icon-home:before {
  content: "\e905";
}
.icon-like:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e91d";
}
.icon-medal-1:before {
  content: "\e907";
}
.icon-medal-2:before {
  content: "\e908";
}
.icon-megaphone:before {
  content: "\e909";
}
.icon-milk-2:before {
  content: "\e90a";
}
.icon-navigation:before {
  content: "\e91e";
}
.icon-rice-2:before {
  content: "\e90b";
}
.icon-rice:before {
  content: "\e91f";
}
.icon-school:before {
  content: "\e920";
}
.icon-star:before {
  content: "\e921";
}
.icon-store:before {
  content: "\e90c";
}
.icon-tree-palm:before {
  content: "\e90d";
}
.icon-trophy:before {
  content: "\e922";
}
.icon-truck-2:before {
  content: "\e90e";
}


/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}


body {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #1d611c;
  font-size: 19px;
}

.hero {
  padding: 60px 0;
  height: 80vh;
  display: flex;
  align-items: center;
  color: #fff;
  padding-top: 150px;
  background-image: url('assets/images/bg.png');
  background-size: 103%;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #2c8c48;
}

.hero h2 {
  font-size: 300%;
}

.hero p {
  font-size: 150%;
}

.section-title {
  font-weight: 600;
  font-size:44px;
}


.product-list {
  list-style-type: none;
  padding-left: 0;
}

.product-list li:before {
  content: "ÃƒÂ¢Ã…â€œÃ¢â‚¬Å“ ";
  color: #318d47;
}

.header-top {
  margin-bottom: 15px;
  border-bottom: 1px solid #598d4b3b;
  padding-bottom: 5px;
  transition: 0.3s ease all;
  opacity: 1;
  transform: translateY(0);
}


.header-top a {
  color: #1d611c;
  text-decoration: none !important;
  transition: 0.3s ease all;
}

.header-top svg {
  height: 20px;
  width: 20px !important;
  margin-right: 10px;
}



.navbar {
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  display: block !important;
}
.navbar-nav{
  width:100%;
}
.navbar img {
  width: 85px;
  padding: 0;
  transition: all 0.2s ease;
}



.navbar.shrink {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.navbar.shrink img {
  width: 65px;
  transition: all 0.2s ease;
}

.navbar.shrink .header-top {
  transform: translateY(-100%);
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.logo-text {
  display: none;
}

.logo-text h1 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

.logo-text p {
  font-size: 15px;
  margin: 0;
}

.nav-link {
  color: #3a5e30 !important;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}


@media (max-width: 1024px) {
  .nav-item{
    display:none;
  }
  .nav-item.call{
    display:block;
  }
}
.call a {
  font-weight: 700;
  color: #fff;
  background: #2c8c48;
  padding: 10px 20px;
  border-radius: 30px;
  margin-left: 20px;
  text-decoration: none;
  transition: 0.3s ease all;
}
.call a:hover{
  opacity: 0.9;
}
.products-section {
  background: #F3FCF0;
  background-image: url(assets/images/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.product {
  padding: 20px 0;
  height: 100%;
  border: 1px solid #689d4c42;
  border-radius: 10px;
  background: #fff;
}

.product-view {
  position: relative;
}

.product-view:After {
  content: "";
  position: absolute;
  background: #689e5d29;
  width: 246px;
  height: 65px;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  border-radius: 100%;
}

.product-view img {
  height: 220px;
  position: relative;
  z-index: 2;
}


.product-text {
  margin-top: 20px;
  padding: 0 20px;
}

.product-text h5 {
  font-size: 22px;
  font-weight: 600;
}







.mission {
  background-image: url(assets/images/bg.png);
  background-position: center;
  background-size: cover;
}
.mission .card{
  border: 1px solid #689d4c42;
  border-radius: 10px;
  padding: 30px;
  background: #5a9f5c0a;
}
.mission .icon {
  font-size: 46px;
  background: #bfd74fa6;
  width: 90px;
  height: 90px;
  line-height: 99px;
  text-align: center;
  border: 1px solid #5a9f5c;
  margin-bottom: 25px;
  border-radius: 100%;
  color: #468b49;
}

.mission .card .card-title{
  font-size:24px;
  font-weight:600;
  margin-bottom:15px;
}

.choose h3{
  text-align: center;
  margin-bottom:40px;
}
.choose span{
  font-size: 46px;
  text-align: center;
  margin-bottom: 30px;
  display: block;
}
.choose .text-center{
  border: 1px solid #689d4c42;
  border-radius: 10px;
  padding: 30px;
  background: #5a9f5c0a;
  height:100%;
}
.footer{
  background-color: #d0de69;
  padding: 30px 0!important;
  color: #2c8c48!important;
}
.footer p{
  margin:0;
  font-size:15px;
}
.contact {
  text-align: center;
  background: #2c8c48;
  padding: 100px 0;
}
.footer-contact{text-align: left;color: #fff;}
.footer-contact span {
  font-size: 30px;
  float: left;
  line-height: 55px;
  background: transparent;
  width: 55px;
  text-align: center;
  margin-right: 10px;
}


@media (max-width:1024px){
  body{
    font-size:14px;
  }
}